<template>
  <div class="jumbotron">
    <b-container class="mt-4">
      <h1 class="my-4" v-if="page">{{ page.title }}</h1>
      <template v-if="newsList">
        <div class="news-list">
          <ul class="d-flex flex-wrap mx-0">
            <li class="col-12 col-md-6 p-3" v-for="news_item in newsList.list" :key="news_item.id">
              <!--<b-link rel="" :to="news_item.url" :target="news_item.newWindow?'_blank':''">-->
              <b-link
                :href="news_item.url ? news_item.url : ''"
                :to="
                  news_item.url
                    ? ''
                    : {
                        name: pageName == 'market-notice' ? 'market-notice-detail' : 'news-detail',
                        params: { id: news_item.id }
                      }
                "
                :target="news_item.newWindow ? '_blank' : ''"
              >
                <div class="d-flex flex-wrap pb-3 content_title align-items-center">
                  <div class="col-12 col-md-2 p-0">
                    <!-- <b-img :src="`${require('@/images/s_banner1.png')}`" /> -->
                    <b-img
                      :src="news_item.cover ? news_item.cover : `${require('@/assets/images/s_banner0.png')}`"
                      :alt="news_item.title"
                    />
                  </div>
                  <div class="col-12 col-md-9 my-auto item_text">
                    <p class="blue">{{ moment(news_item.inputAt).format('YYYY-MM-DD') }}</p>
                    <p class="sub-title mt-0">{{ news_item.title }}</p>
                  </div>
                </div>
              </b-link>
            </li>
          </ul>
        </div>
        <div class="text-center my-5" v-if="newsList.total / curNum > curpage">
          <a rel="" class="btn btn-round news_more" @click="handleMore">{{ $t('查看更多') }} + </a>
        </div>
      </template>
    </b-container>
  </div>
</template>

<script>
import { lanToISO639, ISO639ToLan } from '@/utils'
import { mapState } from 'vuex'
import updateSeoInfo from '@/utils/meta'
import Lifecycle from '@/mixins/lifecycle'
import moment from 'moment'
export default {
  components: {},
  data() {
    return {
      moment: moment,
      chartData: [],
      curpage: 1,
      newsList: {},
      curNum: 10,
      title: null,
      pageName: ''
    }
  },
  computed: {
    ...mapState('page', ['news']),
    ...mapState('common', ['page']),
    alreadyInited: function() {
      return !!this.menu
    },
    lang() {
      return lanToISO639(this.$getLocale())
    }
  },
  watch: {},
  methods: {
    fetchData() {
      let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
      path = path.replace(`preview/`, '')
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      const { preview, id } = this.$route.query
      this.pageName = this.$route.name
      return new Promise((resolve, reject) => {
        Promise.all([
          this.fetchInitData(),
          this.$store.dispatch('common/FETCH_PAGE', {
            path,
            cookies,
            preview,
            id
          }),
          this.$store.dispatch('page/FETCH_NEWS', {
            cookies,
            slug: this.pageName == 'market-notice' ? 'market-notice' : 'latest-news',
            limit: 10
          })
        ])
          .finally(() => {
            this.hanldeInit()
            resolve(updateSeoInfo.call(this))
          })
          .catch(err => {
            console.log('object', err)
            reject()
          })
      })
    },
    hanldeInit() {
      this.newsList = this.news
      if (this.page) this.title = this.page.title
    },
    handleMore() {
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      return new Promise((resolve, reject) => {
        this.curpage += 1
        Promise.all([
          this.$store.dispatch('page/FETCH_NEWS', {
            cookies,
            slug: this.$route.name == 'market-notice' ? 'market-notice' : 'latest-news',
            page: this.curpage,
            limit: 10
          })
        ])
          .then(() => {
            this.newsList.list = this.newsList.list.concat(this.news.list)
            resolve()
          })
          .catch(err => {
            reject()
          })
      })
    }
  },
  seoInfo() {
    try {
      return {
        title: this.page.metaTitle,
        description: this.page.metaDescription || this.page.metaTitle,
        keywords: this.page.metaKeywords || this.page.metaTitle,
        metas: this.page.metaExtras
      }
    } catch (err) {}
  },
  beforeCreate() {},
  created() {},
  serverPrefetch() {
    return this.fetchData()
  },
  mounted() {
    this.fetchData()
  },
  head() {
    return {
      title: '市場資訊 - 大豐銀行',
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'description',
          name: 'description',
          content: 'My custom description'
        }
      ]
    }
  }
}
</script>
